import React from 'react';
import { authAtom } from '@/atoms/auth';
import { useRecoilState } from 'recoil';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element }: { element: any }) => {
  const [auth, setAuth] = useRecoilState(authAtom);

  return auth.isSignedIn ? element : <Navigate to="/signIn" />;
};

export default PrivateRoute;
