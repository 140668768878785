import React, { FunctionComponent, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BASE_URL } from '@/config';
import axios from 'axios';
import { signInWithCustomToken } from 'firebase/auth';
import { firebaseAuth } from '@/firebase';

interface OwnProps {}

type Props = OwnProps;

const Kakao: FunctionComponent<Props> = (props) => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`/user/kakao/oauth?code=${code}`, {
        baseURL: BASE_URL,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((response) => {
        console.log(response.data);
        const token = response.data;
        signInWithCustomToken(firebaseAuth, token)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            console.log(user);
            navigate('/');
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            alert(errorMessage);
          });
      });
  }, []);

  return <div></div>;
};

export default Kakao;
