export enum OAuthType {
  EMAIL = 'EMAIL',
  GOOGLE = 'GOOGLE',
  APPLE = 'APPLE',
  KAKAO = 'KAKAO',
  NAVER = 'NAVER',
}

export enum Authority {
  UNKNOWN = 'UNKNOWN',
  ADMIN = 'ADMIN',
  CLIENT = 'CLIENT',
}

export enum ComsUserStatus {
  UNKNOWN = 'UNKNOWN',
  INITIAL = 'INITIAL',
  HOLDING = 'HOLDING',
  ACTIVE = 'ACTIVE',
}

export enum OrderStatus {
  UNKNOWN = 'UNKNOWN',
  ORDERED = 'ORDERED',
  ACCEPTED = 'ACCEPTED',
  SENT = 'SENT',
  DELIVERED = 'DELIVERED',
  CANCELED = 'CANCELED',
}

export enum DeliveryMethod {
  UNKNOWN = 'UNKNOWN',
  DIRECT = 'DIRECT',
  PARCEL = 'PARCEL',
}

export enum ProductState {
  UNKNOWN = 'UNKNOWN',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
