import React, { FunctionComponent } from 'react';
import { BASE_URL } from '@/config';
import axios from 'axios';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Button, TextField } from '@mui/material';
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { firebaseAuth } from '@/firebase';
import { authAtom } from '@/atoms/auth';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { OAuthType } from '@/constants';

interface OwnProps {}

type Props = OwnProps;

type SignUpFormData = {
  email: string;
  password: string;
  passwordConfirm: string;
};

const SignUp: FunctionComponent<Props> = (props) => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid },
  } = useForm<SignUpFormData>();
  const [auth, setAuth] = useRecoilState(authAtom);
  const navigate = useNavigate();

  const handleGoogleSubmit = async (event: any) => {
    event.preventDefault();

    signInWithGoogle();

    /*if (!auth.isSignedIn) {
      const provider = new GoogleAuthProvider();
      try {
        const userCredential = await signInWithPopup(firebaseAuth, provider);
        const { user }: { user: any } = userCredential;
        await signUpOnComsServer(user.accessToken, OAuthType.GOOGLE);
        navigate('/');
      } catch (error: any) {
        alert(error);
      }
    } else {
      const token = localStorage.getItem('token');
      await signUpOnComsServer(token, OAuthType.GOOGLE);
      navigate('/');
    }*/
  };

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(firebaseAuth, provider)
      .then((userCredential) => {
        // Signed in
        const user: any = userCredential.user;
        signInWithComsServer(user.accessToken, OAuthType.GOOGLE)
          .then((response) => {
            console.log('signInWithComsServer success');
          })
          .catch((error: any) => {
            const { errorCode, message: errorMessage } = error.response.data;
            alert(errorMessage);
          });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(errorMessage);
      });
  };

  const signInWithComsServer = (token: any, oauthType: OAuthType | null) => {
    return axios.post(
      `/user/firebase/oauth`,
      { oauthType },
      {
        baseURL: BASE_URL,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const onSubmit: SubmitHandler<SignUpFormData> = async (formData) => {
    console.log(formData);

    createUserWithEmailAndPassword(
      firebaseAuth,
      formData.email,
      formData.password
    )
      .then((userCredential: any) => {
        // Signed in
        const user = userCredential.user;
        console.log(userCredential);
        signInWithComsServer(user.accessToken, OAuthType.EMAIL)
          .then((response) => {
            console.log('signInWithComsServer success');
          })
          .catch((error: any) => {
            const { errorCode, message: errorMessage } = error.response.data;
            console.log(error);
          });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode === 'auth/email-already-in-use') {
          alert('이미 가입된 이메일입니다.');
          reset({
            password: '',
            passwordConfirm: '',
          });
        } else {
          alert(errorMessage);
        }
        // ..
      });
  };

  return (
    <div className="container mx-auto my-5 flex flex-col w-[50%]">
      <form onSubmit={handleGoogleSubmit}>
        <div className="flex flex-col">
          <label className="nickname">Enter your nickname</label>
          <input className="nickname" type="text" name="nickname" />
          <button className="signup" type="submit">
            구글 회원가입
          </button>
        </div>
      </form>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col">
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                label="이메일"
                {...register('email', {
                  required: {
                    value: true,
                    message: '이메일을 입력해 주세요.',
                  },
                  pattern: {
                    value:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: '이메일 형식에 맞게 입력해 주세요.',
                  },
                })}
                {...field}
              />
            )}
          />
          {errors.email && <p>{errors.email.message}</p>}
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                label="비밀번호"
                type="password"
                {...register('password', {
                  required: {
                    value: true,
                    message: '비밀번호를 입력해 주세요.',
                  },
                  minLength: {
                    value: 8,
                    message: '최소 8자 이상 입력해 주세요.',
                  },
                })}
                {...field}
              />
            )}
          />
          {errors.password && <p>{errors.password.message}</p>}
          <Controller
            name="passwordConfirm"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                label="비밀번호 확인"
                type="password"
                {...register('passwordConfirm', {
                  validate: {
                    matchPassword: (value) => value === watch('password'),
                  },
                })}
                {...field}
              />
            )}
          />
          {errors.passwordConfirm?.type === 'matchPassword' && (
            <span>비밀번호가 다릅니다.</span>
          )}

          <Button variant="contained" type="submit">
            이메일 회원가입
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SignUp;
