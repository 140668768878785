import React, { Suspense, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignIn from '@/pages/SignIn';
import PrivateRoute from '@/components/PrivateRoute';
import SignUp from '@/pages/SignUp';
import { authAtom } from '@/atoms/auth';
import { useRecoilState } from 'recoil';
import { firebaseAuth } from '@/firebase';
import { BASE_URL } from '@/config';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import Kakao from '@/pages/Kakao';
import Naver from '@/pages/Naver';

const Main = React.lazy(() => import('@/pages/Main'));

function App() {
  const [auth, setAuth] = useRecoilState(authAtom);

  useEffect(() => {
    return onAuthStateChanged(firebaseAuth, (firebaseUser: any) => {
      console.log('onAuthStateChanged');
      if (firebaseUser) {
        console.log(firebaseUser);
        setAuth({
          ...auth,
          isSignedIn: true,
          isLoading: false,
          userId: firebaseUser.uid,
          email: firebaseUser.email,
        });

        const accessToken = firebaseUser.accessToken;
        const refreshToken = firebaseUser.refreshToken;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);

        /*axios
          .get(`/user/me`, {
            baseURL: BASE_URL,
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {})
          .catch((error) => {
            if (error.response.status === 401) {
              axios
                .post(
                  `https://securetoken.googleapis.com/v1/token?key=AIzaSyAw3bRNPk4s4aP8xfoAOBxVa3m6PqydM6Y`,
                  {
                    grant_type: 'refresh_token',
                    refresh_token: `${firebaseUser.refreshToken}`,
                  }
                )
                .then((response) => {
                  localStorage.setItem('token', response.data.access_token);
                });
            }
          });*/
      } else {
        localStorage.removeItem('token');
        setAuth({
          ...auth,
          isLoading: false,
          isSignedIn: false,
        });
      }
    });
  }, []);

  return auth.isLoading ? (
    <div>Loading</div>
  ) : (
    <Router>
      <Suspense
        fallback={
          <div className="w-[100vh] h-[100vh] flex justify-center items-center">
            center
          </div>
        }>
        <Routes>
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/signIn" element={<SignIn />} />
          <Route path="/kakao" element={<Kakao />} />
          <Route path="/naver" element={<Naver />} />
          <Route path="/*" element={<PrivateRoute element={<Main />} />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
