import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyAw3bRNPk4s4aP8xfoAOBxVa3m6PqydM6Y',
  authDomain: 'coms-7a09a.firebaseapp.com',
  projectId: 'coms-7a09a',
  storageBucket: 'coms-7a09a.appspot.com',
  messagingSenderId: '626514260272',
  appId: '1:626514260272:web:ed58cecfe7568bb78b18f5',
  measurementId: 'G-D4C6LD8XCX',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(app);
