import Config from '@/config.json';

const BASE_URL =
  process.env.REACT_APP_BASE_URL !== undefined
    ? process.env.REACT_APP_BASE_URL
    : Config.BASE_URL;

const KAKAO_REDIRECT_URI =
  process.env.REACT_APP_KAKAO_REDIRECT_URI !== undefined
    ? process.env.REACT_APP_KAKAO_REDIRECT_URI
    : Config.KAKAO_REDIRECT_URI;

const KAKAO_JAVASCRIPT_KEY =
  process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY !== undefined
    ? process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY
    : Config.KAKAO_JAVASCRIPT_KEY;

const NAVER_REDIRECT_URI =
  process.env.REACT_APP_NAVER_REDIRECT_URI !== undefined
    ? process.env.REACT_APP_NAVER_REDIRECT_URI
    : Config.NAVER_REDIRECT_URI;

const NAVER_CLIENT_ID =
  process.env.REACT_APP_NAVER_CLIENT_ID !== undefined
    ? process.env.REACT_APP_NAVER_CLIENT_ID
    : Config.NAVER_CLIENT_ID;

export {
  BASE_URL,
  KAKAO_REDIRECT_URI,
  KAKAO_JAVASCRIPT_KEY,
  NAVER_REDIRECT_URI,
  NAVER_CLIENT_ID,
};
