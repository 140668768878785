import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RecoilRoot } from 'recoil';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { alpha, createTheme, ThemeProvider } from '@mui/material';

// Create a client
const queryClient = new QueryClient();

let theme = createTheme({
  palette: {
    primary: {
      main: '#f3f4f6',
      dark: '#6b7280',
    },
    secondary: {
      main: '#52575C',
      dark: '#43484b',
      light: '#656a72',
    },
  },
});

theme = createTheme(theme, {
  typography: {
    fontFamily: 'Noto Sans KR, sans-serif',
    allVariants: {
      textTransform: 'none',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: '#F5F6FA',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {},
    },
    MuiTextField: {
      styleOverrides: {
        input: {
          borderRadius: '1.5rem',
          position: 'relative',
          backgroundColor:
            theme.palette.mode === 'light' ? '#ffffff' : '#2b2b2b',
          border: '1px solid #ced4da',
          fontSize: '0.875rem',
          width: '100%',
          padding: '0.75rem 2.75rem 0.75rem 2.75rem',
          letterSpacing: '0.15rem',
          boxSizing: 'border-box',
          height: '3.125rem',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
          ]),
          // Use the system font instead of the default Roboto font.
          fontFamily: 'Noto Sans KR, sans-serif',
          '&:focus': {
            boxShadow: `${alpha(
              theme.palette.primary.main,
              0.25
            )} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
          },
        },
      },
    },
    MuiTimeline: {
      styleOverrides: {
        root: {
          backgroundColor: 'red',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.875rem',
          color: 'white',
          backgroundColor: '#2C2C2C',
        },
        // tooltipArrow: {
        //   backgroundColor: "#2C2C2C",
        // },
        arrow: {
          color: '#2C2C2C',
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <QueryClientProvider client={queryClient}>
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
    </RecoilRoot>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
